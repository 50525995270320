<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="9">
                        <p>{{$t('Quite simply, this tool helps you to send marketing SMS messages to all customers.')}}</p>
                        <p>{{$t('Messages will arrive between 8am and 8pm only.')}}</p>
                        <b-card>
                            <b-row>
                                <b-col cols="4">
                                    <b> عدد العملاء: </b> {{ shoppers_count }} <br>
                                </b-col>
                                <b-col cols="4">
                                    <b>اسم المرسل: </b> {{ snder_name }} <br>
                                </b-col>
                                <!-- <b-col cols="4">
                                    <b>رصيد الرسائل : </b>  {{ sms_balance }}
                                </b-col> -->
                            </b-row>
                            <b-form @submit.prevent="sendSMS">

                                <b-col cols="12" class="p-0">
                                    <b-form-textarea
                                        id="v-message"
                                        v-model="message"
                                        rows="4"
                                        class="mt-1"
                                        @input="countChars"
                                    />
                                </b-col>

                                <b-col cols="12" class="p-0 mt-1">
                                    <span> عدد الأحرف <b-badge variant="primary">{{ remainingChars }}</b-badge> </span>
                                    <span> عدد الرسائل <b-badge variant="primary">{{ numberOfMessages }}</b-badge> </span>
                                    <span> مجموع الرسائل <b-badge variant="primary">{{ totalMessages }}</b-badge> </span>
                                </b-col>

                                <!-- submit -->
                                <b-col cols="12" class="text-right p-0">
                                    <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    variant="primary"
                                    class="mt-1"
                                    >
                                        <template v-if="!isLoadingSave">
                                            {{$t('Send')}}
                                        </template>
                                        <template v-if="isLoadingSave">
                                            {{$t('Sending')}}...
                                            <b-spinner small />
                                        </template>
                                    </b-button>
                                </b-col>
                            </b-form>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    mounted() {
        this.getResults()
    },
    data() {
        return {
            isLoading: true,
            isLoadingSave: false,
            shoppers_count: 0,
            snder_name: '',
            sms_balance: 0,
            message: '',
            maxLength: 210,
            remainingChars: 0,
            numberOfMessages: 1,
            totalMessages: 0,
        }
    },
    methods: {
        getResults() {
            useJwt.get('marketing-offers/sms/get')
            .then((response) => {
                // console.log(response.data)
                this.shoppers_count = response.data.data.shoppers_count
                this.snder_name     = response.data.data.snder_name
                // this.sms_balance    = response.data.data.sms_balance
                this.isLoading = false
            })
            .catch(response => {
                console.log(response);
            });
        },
        countChars(){
            this.message = this.message.replace(/(<([^>]+)>)/gi, "");
            this.remainingChars = this.message.length;
            this.totalMessages = this.shoppers_count * 1;
            this.numberOfMessages = 1;
            if (this.remainingChars > 70){
                this.totalMessages = this.shoppers_count * 2;
                this.numberOfMessages = 2;
            }
            if (this.remainingChars > 140){
                this.totalMessages = this.shoppers_count * 3;
                this.numberOfMessages = 3;
            }
        },
        sendSMS() {
            this.isLoadingSave = true;

            useJwt.post('/marketing-offers/sms/send',{message: this.message, store_id: this.$store.state.store.id})
            .then((response) => {
                // console.log(response.data);
                this.isLoadingSave = false
                this.message = '';
                this.$root.showToast('success', 'sent', '')
                // this.abandoned_cart = response.data.data;
            })
            .catch(error => {
                // console.log(response);
                this.isLoadingSave = false
                this.$root.showAlert('error', error.response.data.message)
            });
        }
    },
}
</script>

<style lang="sass" scoped>

</style>
